export const COLORS = {
    grey: '#dbd8ce',
    grey2: '#a1a38d',
    grey3: '#5a5d58'
}


export const THEME = {
    omni_background: COLORS.grey,
    omni_button: COLORS.grey2
}